<template>
  <div class="dashboard innerpage">
    <HeaderInnerpage></HeaderInnerpage>

    <v-container v-if="!isLoading" class="my-6 innerpage-container">
      <v-row>
        <v-col cols="12">
          <div v-if="currentUser">
            <h2 class="h2">{{ currentUser.user.name }} Dashboard</h2>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="hasLicensesAvailable && !isUserBasic">
        <v-col cols="6" md="6" sm="12">
          <v-card-title class="px-0">
            <h2 class="text-h3">My Licenses</h2>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-data-table
            :loading="dataLicensesLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="acc_headers"
            :items="acc_licenses"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-5 mb-15 align-center"
            :hide-default-footer="true"
          >
            <template v-slot:item.status="{ item }">
              <p>{{ item.status }}</p>
            </template>

            <template v-slot:item.user.first_name="{ item }">
              <p>{{ updateDashboardUser(item, "first_name") }}</p>
            </template>

            <template v-slot:item.user.last_name="{ item }">
              <p>{{ updateDashboardUser(item, "last_name") }}</p>
            </template>

            <template v-slot:item.user.email="{ item }">
              <p>{{ updateDashboardUser(item, "email") }}</p>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip
                bottom
                v-if="
                  item.status != 'inactive' &&
                    item.granted_user_id != currentUser.user.id
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="fireModalDeleteLicense(item.license_key)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Revoke license</span>
              </v-tooltip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">More info about {{ item.name }}</td>
            </template>
          </v-data-table>
        </v-col>
        <v-col
          v-if="
            hasLicensesAvailable &&
              hasLicensesAvailable != 'full' &&
              licenseForm
          "
          cols="6"
        >
          <v-form ref="form" v-model="validLicenseForm" lazy-validation>
            <v-card-title class="px-0">
              <h2 class="text-h3">Send License</h2>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-text-field
              filled
              v-model="acc_user.first_name"
              label="First Name"
              :rules="[v => !!v || 'First Name is required']"
              required
            ></v-text-field>
            <v-text-field
              filled
              v-model="acc_user.last_name"
              label="Last Name"
              :rules="[v => !!v || 'Last Name is required']"
              required
            ></v-text-field>
            <v-text-field
              filled
              v-model="acc_user.email"
              label="Email"
              :rules="emailRules"
              required
            ></v-text-field>
            <span v-if="showErrorLicense" style="color: #860202">
              Error sending license, please try again
            </span>
          </v-form>
          <v-btn
            rounded
            :id="'acc_send'"
            color="secondary"
            class="btn-login"
            @click="sendLicense()"
            :disabled="!validLicenseForm"
          >
            <template v-if="loadingForm">Loading...</template>
            <template v-else>Send</template>
          </v-btn>
        </v-col>
        <v-col v-else cols="6" md="6" sm="12">
          <template v-if="hasLicensesAvailable != 'full'">
            <v-card-title class="px-0">
              <h2 class="text-h3">
                <v-icon class="mr-2" style="color: #00c853; font-size: 24px;"
                  >mdi-check-circle-outline</v-icon
                >License sent
              </h2>
            </v-card-title>
            <v-spacer></v-spacer>
            <p class="body-2">
              Your license was sent. Now it need to be confirmed by the
              recipient<br />
              Now you have <b>{{ hasLicensesAvailable }}</b> license left.<br />
              <v-btn
                rounded
                :id="'acc_send'"
                color="secondary"
                class="btn-login mt-5"
                @click="sendAnother()"
              >
                Send another license
              </v-btn>
            </p>
          </template>
          <template v-else>
            <v-card-title class="px-0">
              <h2 class="text-h3">All your licenses have been assigned</h2>
            </v-card-title>
            <v-spacer></v-spacer>
            <p class="body-2">
              You can revoke access clicking on
              <v-icon>
                mdi-delete
              </v-icon>
              icon.
            </p>
          </template>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="10">
          <v-card-title class="px-0 pb-6">
            <h5 class="h5">Course Analytics</h5>
            <v-spacer></v-spacer>
            <!-- <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>-->
          </v-card-title>

          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="headers"
            :items="reports"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-5 mb-15 align-center dashboardTable"
            :search="search"
            :custom-filter="customDataTableItemsFilter"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
          >
            <template v-slot:item.completed_at="{ item }">
              <td>
                {{ item.completed_at }}
              </td>
            </template>

            <template v-slot:item.final_risk_level="{ item }">
              <td :class="getColor(item.final_risk_level) + ' status'">
                {{ item.final_risk_level }}
              </td>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip
                v-if="
                  !isUserBasic &&
                    item.status != 'In Progress' &&
                    item.status != 'started' &&
                    item.status != 'Incomplete' &&
                    item.active_user_id == null
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    class="icon-link"
                    v-bind="attrs"
                    v-on="on"
                    :href="`/report/${item.id}`"
                  >
                    <v-icon class="mr-2">mdi-playlist-edit</v-icon>
                  </a>
                </template>
                <span>Edit report</span>
              </v-tooltip>

              <v-tooltip
                v-if="
                  item.status != 'In Progress' &&
                    item.status != 'started' &&
                    item.status != 'Incomplete' &&
                    item.final_risk_level != 'Fail'
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    class="icon-link"
                    v-bind="attrs"
                    v-on="on"
                    :href="`/quizcertificate/${item.id}`"
                    target="_blank"
                  >
                    <v-icon class="mr-2">mdi-file-document</v-icon>
                  </a>
                </template>
                <span>View Certificate</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-3"
                    v-bind="attrs"
                    v-on="on"
                    @click="fireModalDeleteReport(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete report</span>
              </v-tooltip>

              <!-- <v-tooltip v-if="item.active_user_id != null" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#ff0000" v-bind="attrs" v-on="on" class="mr-2">
                    mdi-access-point</v-icon
                  >
                </template>
                <span>On live editing</span>
              </v-tooltip> -->
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">More info about {{ item.name }}</td>
            </template>
          </v-data-table>

          <!-- <h2 class="text-h3 mb-5">My Courses</h2>
          <MyCourses />-->
        </v-col>
      </v-row>
    </v-container>
    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>

    <!-- courseslist -->
    <v-container v-if="isUserBasic" class="pb-12 innerpage-container">
      <v-row>
        <v-col>
          <h5 class="h5">Courses</h5>
        </v-col>
      </v-row>
      <v-row v-if="isCoursesLoaded">
        <template v-if="courses.length > 0" transition="fade-transition">
          <v-col v-for="item in courses" :key="item.id" md="4">
            <CourseItemSquare :item="item" />
          </v-col>
        </template>
        <template v-else>
          <v-alert border="top" colored-borderv elevation="5"
            >There are no courses available.
          </v-alert>
        </template>
      </v-row>
      <v-row v-else>
        <!-- loading -->
        <v-col cols="12">
          <p class="text-center text-overline mb-8">Loading Courses</p>
          <v-progress-linear
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <!-- /courseslist -->
    <v-dialog v-model="deleteReportModal" persistent max-width="310">
      <v-card>
        <v-card-title class="headline">
          Are you sure to delete this report?
        </v-card-title>
        <v-card-text>It cant be recovered</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteReport(deleteReportId)"
          >
            Yes
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteReportModal = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteLicenseModal" persistent max-width="310">
      <v-card>
        <v-card-title class="headline">
          Are you sure to revoke this license?
        </v-card-title>
        <v-card-text>It cant be recovered</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteLicense(deleteLicenseId)"
          >
            Yes
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteLicenseModal = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import HeaderInnerpage from "@/components/HeaderInnerpage";
import ReportService from "@/services/report.service";
import ResultsService from "@/services/results.service";
import LicenseService from "@/services/license.service";
import CoursesService from "../services/courses.service";
import moment from "moment";
import { Role } from "@/helpers/roles";
import _ from "lodash";
import CourseItemSquare from "@/components/CourseItemSquare";

// import MyCourses from "@/components/MyCourses";

export default {
  name: "Generic",
  components: {
    HeaderInnerpage,
    CourseItemSquare
  },
  data() {
    return {
      showErrorLicense: false,
      loadingForm: false,
      isCoursesLoaded: false,
      courses: [],
      dialog: false,
      snackbar: false,
      alert: "",
      dataLoading: true,
      dataLicensesLoading: true,
      options: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      search: "",
      acc_user: {
        first_name: "",
        last_name: "",
        email: ""
      },
      acc_licenses: [],
      headers: [
        { text: "Course Title", value: "courseDetails[0].title" },
        {
          text: "Completed",
          value: "completed_at",
          align: "center",
          width: "110px"
        },
        {
          text: "Status",
          value: "final_risk_level",
          align: "center",
          width: "110px"
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          width: "150px",
          sortable: false,
          filterable: false
        }
      ],
      acc_headers: [
        { text: "First Name", value: "user.first_name", width: "6%" },
        { text: "Last Name", value: "user.last_name", width: "6%" },
        { text: "Email", value: "user.email" },
        { text: "Status", value: "status" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false
        }
      ],
      reports: [],
      expanded: [],
      singleExpand: true,
      deleteReportModal: false,
      deleteLicenseModal: false,
      deleteReportId: null,
      deleteLicenseId: null,
      isLoading: false,
      licenseForm: true,
      validLicenseForm: true,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        // (v) => (v && this.doesUserExist != true) || "Email already exists.",
      ]
    };
  },

  watch: {
    options: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedReports();
        }
      },
      deep: true
    }
  },

  computed: {
    hasLicensesAvailable() {
      if (this.acc_licenses.length) {
        if (this.acc_licenses.find(e => e.status == "inactive")) {
          return this.acc_licenses.filter(e => e.status == "inactive").length;
        } else {
          return "full";
        }
      } else {
        return false;
      }
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    isUserBasic() {
      let userBasic = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserBasic) {
          userBasic = true;
        }
      });
      return userBasic;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    this.initialize();
  },

  mounted() {},

  methods: {
    updateDashboardUser(item, key) {
      if (item && item.user && item.user[key]) {
        return item.user[key];
      } else if (item && item.status == "pending" && item["granted_" + key]) {
        return item["granted_" + key];
      }
    },

    sendAnother() {
      this.acc_user = {
        first_name: "",
        last_name: "",
        email: ""
      };
      this.licenseForm = true;
    },

    async sendLicense() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        //TODO #372 - Adapt error message to backend response
        this.showErrorLicense = false;
        try {
          this.loadingForm = true;
          await LicenseService.sendLicense({
            data: this.acc_user
          });
          this.licenseForm = false;
          this.loadingForm = false;
          this.getMyLicenses();
        } catch (e) {
          this.loadingForm = false;
          this.showErrorLicense = true;
          console.log("error getting courses", e);
        }
      }
    },

    fireModalDeleteLicense(id) {
      this.deleteLicenseId = id;
      this.deleteLicenseModal = true;
    },

    fireModalDeleteReport(id) {
      this.deleteReportId = id;
      this.deleteReportModal = true;
    },

    async deleteLicense() {
      this.deleteLicenseModal = false;
      await LicenseService.revoke({ id: this.deleteLicenseId });
      this.getMyLicenses();
    },

    async deleteReport() {
      this.isLoading = true;
      this.deleteReportModal = false;
      await ReportService.remove({ id: this.deleteReportId });
      this.isLoading = false;
    },

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.getMyLicenses();
      this.getPagedReports();
      this.getCourses();
    },

    async getMyLicenses() {
      this.dataLicensesLoading = true;
      const myLicenses = await LicenseService.getMyLicenses({});
      this.acc_licenses = myLicenses.data.data;
      this.dataLicensesLoading = false;
    },

    async getPagedReports() {
      this.dataLoading = true;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.options;

      const response = await ReportService.getReportByUserId({
        id: this.currentUser.user.id,
        query: {
          page: page
        }
      });

      this.reports = response.data.data.slice();
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      this.reports = this.reports.filter(report => {
        return report.status === "completed";
      });

      console.log("reports", this.reports);
      if (itemsPerPage > 0) {
        this.reports = this.reports.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      this.formatReportDate();
      //this.checkOnLiveFix();
      // this.formatReportName();
      this.dataLoading = false;
    },

    async getCourses() {
      try {
        let response;
        if (this.isAdminEditor) {
          response = await CoursesService.getAllCourses();
          this.courses = response.data.data.slice();
        } else {
          response = await CoursesService.get({
            orgId: this.orgId
          });

          this.courses = response.data.slice();
        }


        this.isCoursesLoaded = true;
      } catch (e) {
        console.log("error getting courses", e);
        this.isCoursesLoaded = true;
      }
    },

    // async checkOnLiveFix() {
    //   let refresh = false;
    //   await this.reports.forEach(rep => {
    //     if (rep.active_user_id == this.currentUser.user.id) {
    //       ReportService.update({
    //         id: rep.id,
    //         data: {
    //           active_user_id: null
    //         }
    //       });
    //       refresh = true;
    //     }
    //   });
    //   if (refresh) {
    //     this.getPagedReports();
    //   }
    // },

    async formatReportDate() {
      await this.reports.map(rep => {
        return (rep.completed_at = moment(rep.completed_at).format("DD/MM/YYYY"));
      });
    },

    async formatReportName() {
      await this.reports.map(res => {
        this.getQuizDetail(res.id).then(detailRes => {
          return (res.quizResults = detailRes || null);
        });
      });
    },

    async getQuizDetail(id) {
      const getResults = await ResultsService.get({
        id
      });
      // group by quiz_id
      return _.chain(getResults.data.data)
        .groupBy("quiz_id")
        .toPairs()
        .map(currentItem => {
          return _.zipObject(["quiz_id", "questions"], currentItem);
        })
        .map(currentItem => {
          // convert quiz_id to int
          currentItem.quiz_id = parseInt(currentItem.quiz_id);
          return currentItem;
        })
        .value();
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    customDataTableItemsFilter(value, search, items) {
      /*
      Filter for individual words in search string. Filters
      all object values rather than just the keys included
      in the data table headers.
       */
      const wordArray = search
        .toString()
        .toLowerCase()
        .split(" ")
        .filter(x => x);
      return wordArray.every(word =>
        JSON.stringify(Object.values(items))
          .toString()
          .toLowerCase()
          .includes(word)
      );
    },

    getColor(risk) {
      if (risk == "Fail") return "error";
      //else if (risk == "Moderate") return "warning";
      else return "success";
    },
    deleteItem(item) {
      const index = this.reports.indexOf(item);
      confirm("Are you sure you want to delete this report?") &&
        this.reports.splice(index, 1);
    }
  }
};
</script>
