<template>
  <v-card class="mx-auto rounded-br-xl" max-width="400" elevation="0">
    <v-img class="white--text align-end" height="200px" :src="item.full_image">
    </v-img>

    <v-card-subtitle class="pb-0">
      <a class="hlink" :href="`/plans/${item.id}`"
        ><h5 class="h5">{{ item.title }}</h5></a
      >
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <div class="py-3">
        <v-icon color="primary">mdi-clock-outline</v-icon>
        {{ timeConvert(item.duration) }}
        <v-icon class="ml-3" color="primary">mdi-license</v-icon>
        {{ item.category.name }}
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Response",
  props: {
    item: {}
  },
  methods: {
    onClickButton(itemId) {
      this.$emit("clicked", itemId);
    },
    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if (rminutes > 1 && rhours > 1) {
        return rhours + " hours and " + rminutes + " minutes";
      } else if (rminutes > 1 && rhours === 0) {
        return rminutes + " minutes";
      } else {
        return rhours + " hours";
      }
    }
  }
};
</script>
<style scoped>
.mybutton {
  text-transform: none !important;
}
</style>
